// eslint-disable-next-line import/order -- must be imported first to catch errors
import './instrument';

import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

// eslint-disable-next-line import/order -- don't import components before this line otherwise style precedence will break
import './styles/index.css';

import { LastResortError } from './components/Errors/LastResort/LastResortError';
import { RootTheme } from './components/Theme/RootTheme';
import { LastResortLayout } from './layouts/LastResort/LastResort';
import { Provider } from './providers/provider_client';
import { Router, router } from './routers/router_client';
import { getToken } from './services/user/userService';

const params = window.location.search.replace(/\?/g, '');

const searchParams = new URLSearchParams(params);

const googleRedirectState = searchParams.get('state');
let state: Record<string, any> = {};
try {
  const stateAsString = atob(googleRedirectState ?? '');
  const parsedState = JSON.parse(stateAsString || 'null');
  if (parsedState) {
    state = parsedState;
  }
} catch (error: any) {
  if (searchParams.has('error_description')) {
    Sentry.captureMessage(`Failed to parse Google redirect state after invalid sign-in: ${error.message}`, 'warning');
  } else {
    // capture exception only if it is a legitimate sign-up error
    Sentry.captureException(error);
  }
}

// SSO : Google Sign In
if (searchParams.has('code')) {
  const code = searchParams.get('code')!;

  getToken(code).then(
    () => {
      const redirectTo: string = state?.redirectTo || '/dashboard';
      const newSearchParams = new URLSearchParams(state?.search ?? '');
      newSearchParams.delete('redirectTo');

      const newHref = `${window.location.protocol}//${window.location.host}/${
        redirectTo.startsWith('/') ? redirectTo.slice(1) : redirectTo
      }?${newSearchParams.toString()}`;

      window.location.href = newHref;
    },
    (reason) => {
      throw new Error(`Failed fetching token with Google sign in code '${code}' error: ${reason}`);
    },
  );
} else if (searchParams.has('error_description')) {
  const errMessage = searchParams.get('error_description')?.replace('PreSignUp failed with error ', '');
  // eslint-disable-next-line prefer-const
  let { path, redirectData, search } = state ?? {};

  const newSearchParams = new URLSearchParams(search ?? '');
  const navigateCompatibleSearch = `?${newSearchParams?.toString()}`;

  if (!path) {
    path = '/auth/sign-in';
  }

  if (redirectData) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- eslint onboarding
    router.navigate(
      { pathname: path, search: navigateCompatibleSearch },
      {
        state: { ...redirectData, googleLoginError: errMessage },
      },
    );
  } else {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- eslint onboarding
    router.navigate(
      { pathname: path, search: navigateCompatibleSearch },
      {
        state: { googleLoginError: errMessage },
      },
    );
  }
  window.location.reload();
} else {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <Sentry.ErrorBoundary
      fallback={
        <LastResortLayout>
          <LastResortError />
        </LastResortLayout>
      }
    >
      <Provider>
        <RootTheme>
          <Router />
        </RootTheme>
      </Provider>
    </Sentry.ErrorBoundary>,
  );
}
